import { PaymentLayout } from "@/builder/enums/PaymentLayout";
import { PaymentMethod } from "@/builder/enums/PaymentMethod";
import { TestPaymentMethods } from "@/builder/enums/TestPaymentMethods";
import { FormType } from "@/builder/enums/FormType";
import { PageType } from "@/builder/enums/PageType";
import { PartialDisplayType } from "@/builder/enums/PartialDisplayType";
import { Direction } from "@/builder/enums/Direction";
import { Fields } from "@/builder/enums/FieldTypes";
import { BuilderTypes } from "@/builder/enums/BuilderTypes";
import { MergeTag } from "@/builder/enums/MergeTag";
import { TributeType } from "@/builder/enums/TributeType";
import { AcknowledgementMethod } from "@/builder/enums/AcknowledgementMethod";
import { FormButtonType } from "./FormButtonType";
import { Frequency, PaymentMethodType } from "../../enums";
import { PageTemplateType } from "./PageTemplateType";

export {
    PaymentLayout,
    PaymentMethod,
    TestPaymentMethods,
    FormType,
    PageType,
    PartialDisplayType,
    Direction,
    Fields,
    BuilderTypes,
    MergeTag,
    TributeType,
    AcknowledgementMethod,
    PageTemplateType,
    Frequency,
    PaymentMethodType,
    FormButtonType
};

export enum BuilderStep
{
    Template = 0,
    Setup = 1,
    Editor = 2,
    Publish = 3,
}

export enum GoalBasis {
    AmountRaised = "amount-raised",
    TotalGifts = "total-gifts",
}

export enum Position {
    Top = "top",
    Right = "right",
    Bottom = "bottom",
    Left = "left",
}

export enum ContainerType {
    Body = "body",
    Column = "column",
    Form = "form",
    Form_Step = "form-step",
    Prompt = "prompt",
    Confirmation = "confirmation",
}

export enum ComponentType {
    Header = "header-component",
    Footer = "footer-component",
    Section = "section-component",
    Form = "form-component",
    FormStep = "form-step-component",
    TextInput = "text-input-component",
    Text = "text-component",
    Button = "button-component",
    ButtonGroup = "button-group-component",
    Column = "column-component",
    CustomField = "custom-field-component",
    CustomCollection = "custom-collection-component",
    Image = "image-component",
    Video = "video-component",
    HTML = "html-component",
    DonationBlock = "donation-block-component",
    ProgressBar = "progress-bar-component",
    Icon = "icon-component",
    Payment = "payment-component",
    Frequency = "frequency-component",
    CoverCost = "cover-cost-component",
    Confirmation = "confirmation-component",
    Tribute = "tribute-component",
    DoubleTheDonation = "double-the-donation-component",
    Premium = "premium-component",
    Spacer = "spacer-component",
    Divider = "divider-component",
    AnonymousGiving = "anonymous-giving-component",
    Prompt = "prompt-component",
}

export enum PromptType {
    RecurringAsk = "recurring-ask",
    PremiumAsk = "premium-ask",
}

export enum PromptDisplayMode {
    Modal = "modal",
    Tray = "tray",
}

export enum PromptDecision {
    Accept = "accept",
    Decline = "decline",
}

export enum RecurringAskTrigger {
    AfterFrequencyStep = 0,
    OnSubmit = 1,
    Both = 2,
}

export enum SectionLayoutType {
    OneColumn = "single-column",
    TwoColumns_25_75 = "two-columns-25-75",
    TwoColumns_33_66 = "two-columns-33-66",
    TwoColumns_50_50 = "two-columns-50-50",
    TwoColumns_66_33 = "two-columns-66-33",
    TwoColumns_75_25 = "two-columns-75-25",
    ThreeColumns_33_33_33 = "three-columns-33-33-33",
    ThreeColumns_25_25_50 = "three-columns-25-25-50",
    ThreeColumns_25_50_25 = "three-columns-25-50-25",
    ThreeColumns_50_25_25 = "three-columns-50-25-25",
    FourColumns_25_25_25_25 = "four-columns-25-25-25-25",
}

export enum DeviceDisplayType {
    Desktop = "desktop",
    Mobile = "mobile",
    All = "all",
}

export enum AdminFeeType {
    Percentage = "percentage",
    Fixed = "fixed",
}

export enum Alignment {
    Start = "start",
    Center = "center",
    End = "end",
}

export enum ProjectDisplayType {
    Dropdown = 0,
    Card = 1,
    Dropdown_With_Card = 2,
}

export enum GiftArrayType {
    GiftArray = 0,
    GiftArray_OtherAmount = 1,
    CustomAmount = 2,
    FixedAmount = 3,
}

export enum FrequencyDisplayType {
    Buttons = 0,
    Checkbox = 1,
    Tabs = 2
}

export enum SubmitAction {
    Message,
    Redirect,
}

export enum ModalDisplayType {
    Lightbox,
    Panel,
    Popover,
}

export enum FieldStyle {
    Default,
    Underline,
    Compact,
}

export enum ConfirmationType {
    Custom,
    Redirect,
}

export enum SmartGiftArrayBasis {
    SuggestedAmount,
    AverageGiftAmount,
    LastGiftAmount
}

export enum TrustFAQItemType {
    HoverText = "hover-text",
    ExternalLink = "external-link",
}
