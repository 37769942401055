import { ProjectSplit, PremiumResponseModel } from "@/builder/interfaces";
import { AddressType, Frequency, PaymentMethodType } from "@/enums";
import DonorSearchModel from "@/interfaces/donors/DonorSearchModel";
import VirtualTerminalModel from "@/interfaces/virtual-terminal/VirtualTerminalModel";
import { defineStore } from "pinia";
import { Toast, ToastType } from "@/components/shared/toast/interfaces";
import VirtualTerminalPaymentRequest from "@/interfaces/virtual-terminal/VirtualTerminalPaymentRequest";
import { ApiUrls } from "@/constants";
import { AxiosInstance } from "axios";
import VirtualTerminalDonorRequest from "@/interfaces/virtual-terminal/VirtualTerminalDonorRequest";
import Address from "@/interfaces/Address";
import { PaymentMethodCreateParams, Stripe, StripeElements, StripePaymentElement } from "@stripe/stripe-js";

interface DataObject {
    step: number;
    maxStep: number;
    loading: boolean;
    isProcessing: boolean;
    isPaymentSubmitted: boolean;
    donor: VirtualTerminalDonorRequest;
    gift: Gift;
    segment: string;
    terminal: VirtualTerminalModel | null;
    organizationId: number | null;
    isValid: boolean;
    modelState: any;
    toasts: Toast[];

    submissionRequiresAction: boolean;
    paymentClientSecret: string | null;
    paymentId: string | null;

    stripe: Stripe | null;
    stripeElements: StripeElements | null;
    paymentElement: StripePaymentElement | null;
    paymentElementError: string | null;
}

export enum VirtualTerminalStep {
    donorInformation = 0,
    giftInformation = 1,
    premiumOfferings = 2,
    paymentDetails = 3,
}

export interface Gift {
    amount: string | null;
    totalWithCost: string | null;
    showCostPreview: boolean;
    paymentMethodId: string | null;
    frequency: Frequency; //one-time if null
    coverCosts: boolean;
    creditCardType: string | null;
    paymentMethodType: PaymentMethodType | null;
    comments: string | null;
    startDate: string | null;
    projectSplits: ProjectSplit[];
    premium: PremiumResponseModel | null;
}

export interface DonateResponse {
    success: boolean,
    message: string | null,
    transactionId: string | null,
    requireAction: boolean,
    paymentClientSecret: string | null,
}

export const formatCurrency = (val: string) => {
    if (!val) return "0.00";

    const parsedAmount = parseFloat(
        `${!val ? 0 : val}`.replace(",", "")
    ).toFixed(2);

    return Number(parsedAmount).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const amountToNumber = (amount: string | number | null) => {
    //if amount is a number return it
    if (typeof amount === "number") return amount;

    //exit if amount is null or empty
    if (!amount || amount.length === 0) return 0;

    return Number.parseFloat(amount?.replace(",", "") ?? "0");
}

export const useVirtualTerminalStore = defineStore({
    id: "virtualTerminalStore",
    state(): DataObject {
        return {
            step: VirtualTerminalStep.donorInformation,
            maxStep: VirtualTerminalStep.paymentDetails,
            loading: false,
            isProcessing: false,
            isPaymentSubmitted: false,
            donor: {
                id: null,
                title: null,
                firstName: "",
                middleName: null,
                lastName: "",
                suffix: null,
                email: null,
                phone: null,
                isOrganization: false,
                organizationName: null,
                crmKey: null,
                notes: null,
                phoneOptIn: false,
                noEmailAddress: false,
                billingAddress: {
                    address1: null,
                    address2: null,
                    city: null,
                    state: null,
                    postal: null,
                    countryString: "US",
                    addressType: AddressType.Billing,
                },
                shippingAddress: null,
            },
            gift: {
                amount: null,
                totalWithCost: null,
                showCostPreview: false,
                paymentMethodId: null,
                frequency: Frequency.OneTime,
                coverCosts: false,
                creditCardType: null,
                paymentMethodType: null,
                comments: null,
                startDate: null,
                projectSplits: [],
                premium: null,
            },
            segment: "",
            terminal: null,
            organizationId: null,
            isValid: true,
            modelState: {},
            toasts: [] as Toast[],

            submissionRequiresAction: false,
            paymentClientSecret: null,
            paymentId: null,

            stripe: null,
            stripeElements: null,
            paymentElement: null,
            paymentElementError: null,
        };
    },
    getters: {
        totalAmount: (state) => {
            let amount = amountToNumber(state.gift.amount);

            if (state.gift.projectSplits.length > 0) {
                amount = 0;

                for (let i = 0; i < state.gift.projectSplits.length; i++) {
                    const projectSplit = state.gift.projectSplits[i];
                    const projectSplitAmount = amountToNumber(projectSplit.amount);

                    if (isNaN(projectSplitAmount)) continue;

                    amount += projectSplitAmount;
                }
            }

            return amount;
        },

        isPremiumActive: (state) => {
            return (premiumId: string) => {
                return state.gift.premium?.id.toString() === premiumId;
            }
        },

        isRecurring: (state) => {
            return (
                state.gift.frequency != null &&
                state.gift.frequency !== Frequency.OneTime
            );
        },
    },
    actions: {

        async tokenizePaymentMethod() {
            let token = null as string | null;

            //TODO: add other payment methods here

            token = await this.stripeTokenization();

            return token;
        },

        async stripeTokenization() {
            let token = null as string | null;

            if (this.stripeElements) {
                const elements = this.stripeElements;
                await this.stripeElements.submit()
                    .then(async (result) => {
                        const billingDetails = this.buildStripeBillingDetails();

                        await this.stripe?.createPaymentMethod({
                            elements,
                            params: {
                                billing_details: billingDetails
                            },
                        }).then((result) => {
                            if (result.error) {
                                //show error
                                this.paymentElementError = result.error.message ?? "";
                                return null;
                            } else {
                                //set store billing address line 1 if not set using the stripe billing details
                                if (result.paymentMethod?.billing_details) {
                                    this.setBillingAddressFromStripe(result.paymentMethod?.billing_details)
                                }

                                if (result.paymentMethod?.card) {
                                    this.gift.creditCardType = result.paymentMethod.card.brand;
                                }

                                this.gift.paymentMethodId = result.paymentMethod?.id; //we need to set it so the input does not show error state
                                token = result.paymentMethod?.id;
                            }
                    });
                });

                return token;
            }

            return null;
        },

        buildStripeBillingDetails() {
            const billingDetails = {
                name: this.donor.firstName + ' ' + this.donor.lastName,
                email: this.donor.email ?? "",
                address: {
                    line1: this.donor.billingAddress?.address1 ?? "",
                    line2: this.donor.billingAddress?.address2 ?? "",
                    city: this.donor.billingAddress?.city ?? "",
                    state: this.donor.billingAddress?.state ?? "",
                    postal_code: this.donor.billingAddress?.postal?.toString() ?? "",
                    country: this.donor.billingAddress?.countryString ?? 'US' //assume US if no country is provided
                }
            } as PaymentMethodCreateParams.BillingDetails;

            return billingDetails;
        },

        setBillingAddressFromStripe(billingDetails: any) {
            //set billing address line 1
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                address1: this.donor.billingAddress.address1 ?? billingDetails.address?.line1 ?? null
            }

            //set billing address line 2
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                address2: this.donor.billingAddress.address2 ?? billingDetails.address?.line2 ?? null
            }

            //set billing city
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                city: this.donor.billingAddress.city ?? billingDetails.address?.city ?? null
            }

            //set billing state
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                state: this.donor.billingAddress.state ?? billingDetails.address?.state ?? null
            }

            //set billing postal code
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                postal: this.donor.billingAddress.postal ?? billingDetails.address?.postal_code ?? null
            }

            //set billing country
            this.donor.billingAddress = {
                ...this.donor.billingAddress,
                countryString: this.donor.billingAddress.countryString ?? billingDetails.address?.country ?? null
            }
        },

        getPaymentRequest(): VirtualTerminalPaymentRequest {
            if(!this.terminal) throw new Error("Terminal is not set.");

            const paymentRequest: VirtualTerminalPaymentRequest = {
                virtualTerminalId: this.terminal.id,
                nonce: this.terminal.nonce,
                amount: this.gift.amount ?? "",
                donor: this.donor,
                premiumId: this.gift.premium?.id ?? null,
                segment: this.segment,
                donorPaidCosts: this.gift.coverCosts,
                isRecurring: this.gift.frequency !== Frequency.OneTime,
                frequency: this.gift.frequency,
                startDate: this.gift.startDate,
                projects: this.gift.projectSplits,
                paymentMethodType: this.gift.paymentMethodType,
                paymentId: this.paymentId,
                creditCardType: this.gift.creditCardType,
                paymentMethodId: this.gift.paymentMethodId ?? "",
            };

            return paymentRequest;
        },

        async submitPayment(axios: AxiosInstance) {
            this.isProcessing = true;

            if (!this.gift.paymentMethodId) {
                this.addToastError("Payment details are invalid. Please try again.");
                this.isProcessing = false;
                return;
            }

            //tokenize the payment method one last time to ensure its the latest
            this.gift.paymentMethodId = await this.tokenizePaymentMethod();

            axios.post<DonateResponse>(ApiUrls.VirtualTerminal.Payment, this.getPaymentRequest())
                .then((response) => {
                    this.handleSuccess(response.data);
                })
                .catch((error) => {
                    this.handleFailure(error);
                })
                .finally(() => {
                    this.isProcessing = false;
                });
        },

        handleSuccess(response: DonateResponse) {
            if (response.requireAction) {
                // paymentComponent watches this value and will confirm card with stripe
                this.submissionRequiresAction = true;
                this.paymentClientSecret = response.paymentClientSecret;
                return;
            }

            if (response.success) {
                this.isPaymentSubmitted = true;
            }
        },

        handleFailure(error) {
            console.log(error);

            if (error?.response.data[""]) {
                this.addToastError(`${error?.response.data[""][0]}`);
            }
            else if (error?.response.data) {
                if (error.response.data.errors) {
                    this.setModelState(error.response.data.errors);
                }
                else {
                    this.setModelState(error.response.data);
                }

                this.isValid = Object.keys(this.modelState).length === 0;
                this.addToastError("Failed to submit payment. Please check the form for errors.");
            }
            else if (error?.message) {
                this.addToastError(`${error?.message}`);
            }
            else if (error) {
                this.addToastError(error);
            }
            else {
                this.addToastError("Uh oh! There was an unexpected error. Please try again soon.");
            }

            this.goToErrorStep();
        },

        goToErrorStep() {
            if (Object.keys(this.modelState).some((key) => key.startsWith("donor"))) {
                this.step = VirtualTerminalStep.donorInformation;
                return;
            }

            if (Object.keys(this.modelState).some((key) => key.startsWith("project"))) {
                this.step = VirtualTerminalStep.giftInformation;
                return;
            }

            if (Object.keys(this.modelState).some((key) => key.startsWith("premium"))) {
                this.step = VirtualTerminalStep.premiumOfferings;
                return;
            }

            if (Object.keys(this.modelState).some((key) => key.startsWith("payment"))) {
                this.step = VirtualTerminalStep.paymentDetails;
                return;
            }

            this.step = VirtualTerminalStep.donorInformation;
        },

        setAddress(address: Address | null) {
            if (!address) return;

            this.donor.billingAddress.address1 = address.address1;
            this.donor.billingAddress.address2 = address.address2;
            this.donor.billingAddress.city = address.city;
            this.donor.billingAddress.state = address.state;
            this.donor.billingAddress.postal = address.postal;
            this.donor.billingAddress.countryString = address.countryString;
        },

        setDonor(donor: DonorSearchModel) {
            this.donor.id = donor.id;
            this.donor.crmKey = donor.crmKey;
            this.donor.title = donor.title;
            this.donor.firstName = donor.firstName;
            this.donor.lastName = donor.lastName;
            this.donor.suffix = donor.suffix;
            this.donor.email = donor.email;
            this.donor.phone = donor.phone;
            this.donor.notes = donor.notes;
            this.donor.isOrganization = donor.isOrganization;
            this.donor.organizationName = donor.organizationName;
        },

        resetDonor() {
            this.donor.id = null;
            this.donor.crmKey = null;
            this.donor.title = null;
            this.donor.firstName = "";
            this.donor.lastName = "";
            this.donor.suffix = null;
            this.donor.email = null;
            this.donor.phone = null;
            this.donor.notes = null;
            this.donor.isOrganization = false;
            this.donor.organizationName = null;

            this.resetAddress();
        },

        resetAddress() {
            this.donor.billingAddress.address1 = null;
            this.donor.billingAddress.address2 = null;
            this.donor.billingAddress.city = null;
            this.donor.billingAddress.state = null;
            this.donor.billingAddress.postal = null;
            this.donor.billingAddress.countryString = "US";
        },

        nextStep() {
            if (this.step >= this.maxStep) return;
            if (!this.validateStep()) return;

            this.step++;
        },

        previousStep() {
            if (this.step <= 0) return;

            this.step--;
        },

        setStep(VirtualTerminalStep: VirtualTerminalStep) {
            this.step = VirtualTerminalStep;
        },

        validateStep() : boolean {
            this.setModelState({});
            this.isValid = true;

            switch (this.step) {
                case VirtualTerminalStep.donorInformation:
                    this.isValid = this.validateDonorInformation();
                    break;
                case VirtualTerminalStep.giftInformation:
                    this.isValid = this.validateGiftInformation();
                    break;
            }

            return this.isValid;
        },

        validateDonorInformation() : boolean {
            if (!this.donor.firstName) {
                this.modelState["donor.FirstName"] = ["First name is required."];
                this.addToastError(this.modelState["donor.FirstName"][0]);
                return false;
            }

            if (!this.donor.lastName) {
                this.modelState["donor.LastName"] = ["Last name is required."]
                this.addToastError(this.modelState["donor.LastName"][0]);
                return false;
            }

            if (!this.donor.email) {
                this.modelState["donor.Email"] = ["Email is required."];
                this.addToastError(this.modelState["donor.Email"][0]);
                return false;
            }

            if (!this.donor.billingAddress.address1) {
                this.modelState["donor.BillingAddress.Address1"] = ["Address Line 1 is required."];
                this.addToastError(this.modelState["donor.BillingAddress.Address1"][0]);
                return false;
            }

            if (!this.donor.billingAddress.city) {
                this.modelState["donor.BillingAddress.City"] = ["City is required."];
                this.addToastError(this.modelState["donor.BillingAddress.City"][0]);
                return false;
            }

            return true;
        },

        validateGiftInformation(): boolean {
            //check if any of project splits does not have an amount
            const projectSplitWithoutAmount = this.gift.projectSplits.find(
                (x) => amountToNumber(x.amount) <= 0
            );

            if (projectSplitWithoutAmount) {
                this.modelState.amount = "Project amount must be greater than 0.";
                this.addToastError(this.modelState.amount);
                return false;
            }

            //check if the gift amount is 0
            if (this.totalAmount <= 0) {
                this.modelState.amount = "Donation amount must be greater than 0.";
                this.addToastError(this.modelState.amount);
                return false;
            }

            return true;
        },

        setLoading(loading: boolean) {
            this.loading = loading;
        },

        setTerminal(terminal: VirtualTerminalModel | null) {
            this.terminal = terminal;
        },

        generateEmailAddress() {
            if (this.donor.noEmailAddress) {
                this.donor.email = `${this.donor.firstName.toLowerCase()}-${this.donor.lastName.toLowerCase()}@raisedonors-noemail.com`;
            }
        },

        removeProject(projectId: string): void {
            const projectSplitIndex = this.gift.projectSplits.findIndex(
                (x) => x.projectId === projectId
            );
            if (projectSplitIndex > -1) {
                this.gift.projectSplits.splice(projectSplitIndex, 1);
            }

            //if there are no more project splits, set the gift amount to 0
            if (this.gift.projectSplits.length === 0) {
                this.gift.amount = formatCurrency('0');
            }
        },

        setPremium(premium: PremiumResponseModel | null): void {
            this.gift.premium = premium;
        },

        removePremium(): void {
            this.gift.premium = null;
        },

        setOrganizationId(organizationId: string): void {
            this.organizationId = parseInt(organizationId);
        },

        formattedTotal(): string | null {
            if (this.gift.coverCosts) {
                return this.gift.totalWithCost;
            }

            return "$" + formatCurrency(String(this.totalAmount));
        },

        getAmount(projectId?: string | null): string {
            if (!projectId) return this.gift.amount ?? "0";

            return (
                this.gift.projectSplits?.find((x) => x.projectId === projectId)
                    ?.amount ?? ""
            );
        },

        setAmount(
            amount: string,
            projectId?: string | null,
            allowMultipleProjects?: boolean | null
        ): void {
            amount = amountToNumber(amount).toString();
            const formattedAmount = formatCurrency(amount);

            if (!this.gift.projectSplits) this.gift.projectSplits = [];

            if (!allowMultipleProjects) {
                if (!projectId) {
                    //if this gift array is not bound to a project there is a single gift array
                    if (this.gift.projectSplits.length > 0) {
                        //and a user selected an amount after a project (project was set with 0)
                        const projectSplitAmount = (
                            amountToNumber(amount) /
                            this.gift.projectSplits.length).toFixed(2);

                        this.gift.projectSplits.forEach((projectSplit) => {
                            projectSplit.amount = projectSplitAmount;
                        });
                    }
                }
            }

            //if this gift array is not bound to a project, just set the amount
            if (!projectId) {
                this.gift.amount = formattedAmount;
                return;
            }

            //clear out any other project splits if we're not allowing multiple projects
            if (!allowMultipleProjects) {
                this.gift.projectSplits = this.gift.projectSplits.filter(
                    (x) => x.projectId == projectId
                );
            }

            //update the existing project split if it exists
            const existingProjectSplit = this.gift.projectSplits.find(
                (x) => x.projectId === projectId
            );

            if (existingProjectSplit) {
                if (!amount || (existingProjectSplit.amount === amount)) {
                    this.removeProject(projectId);
                    return;
                } else {
                    //update the amount
                    existingProjectSplit.amount = amount;
                }
            }
            else {
                //add a new project split
                const projectSplit: ProjectSplit = {
                    projectId: projectId,
                    projectName: "", //this will get set by a watcher in the project card component
                    amount: amount,
                };

                this.gift.projectSplits.push(projectSplit);
            }

            //update the gift amount
            if (this.gift.projectSplits.length > 0) {
                let projectSum = 0;

                for (let i = 0; i < this.gift.projectSplits.length; i++) {
                    const projectSplit = this.gift.projectSplits[i];
                    const projectSplitAmount = amountToNumber(projectSplit.amount);

                    if (isNaN(projectSplitAmount)) continue;

                    projectSum += projectSplitAmount;
                }

                this.gift.amount = formatCurrency(projectSum.toString());
            }
            else {
                this.gift.amount = formattedAmount;
            }
        },

        setModelState(modelState: any): void {
            this.isValid = Object.keys(modelState).length === 0;
            this.modelState = modelState;
        },

        addToastSuccess(text: string) {
            this.addToast(text, ToastType.Success);
        },

        addToastError(text: string) {
            this.addToast(text, ToastType.Error);
        },

        addToast(text: string, type?: ToastType | null, title?: string | null, duration?: number | null, hasIcon?: boolean | null, iconClass?: string | null, hasCloseButton?: boolean | null) {
            const toast: Toast = {
                id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                text: text,
                title: title ?? null,
                type: type ?? ToastType.Info,
                duration: duration ?? 5000,
                hasIcon: hasIcon ?? false,
                iconClass: iconClass ?? "",
                hasCloseButton: hasCloseButton ?? true
            }

            this.toasts.push(toast);
        },

        clearToast(id: string) {
            const index = this.toasts.findIndex((toast) => toast.id === id);
            this.toasts.splice(index, 1);
        },

        reset() {
            const organizationId = this.organizationId;
            this.$reset();
            this.organizationId = organizationId;
        },
    },
    share: {
        enable: false,
    },
});
