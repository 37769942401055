import { createApp } from "vue";
import App from "@/App.vue";
import "bootstrap";
import router from "@/router";
import { createPinia } from "pinia";
//import { PiniaUndo } from 'pinia-undo';
import { PiniaSharedState } from "pinia-shared-state";
import axios from "axios";
import VueAxios from "vue-axios";
import VueAwesomePaginate from "vue-awesome-paginate";
import { createVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa";
import { publicApiService } from "./services/publicApiService";
import { trackingPixelService } from "./services/trackingPixelService";
import { AppUrls } from "./constants";
import FloatingVue from "floating-vue";
import { LDPlugin } from 'launchdarkly-vue-client-sdk'
import { FeatureFlagService } from "./services/featureFlagService";
import { createHead } from '@unhead/vue';

const vuetify = createVuetify({
    icons: {
        defaultSet: "fa",
        aliases,
        sets: {
            fa,
        },
    },
});

//set axios defaults
const globalAxios = axios.create({
    withCredentials: true,
});

globalAxios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response.status === 401) {
        router.push(AppUrls.Account.Login)
    }
    return Promise.reject(error)
  })

const pinia = createPinia();

pinia.use(
    PiniaSharedState({
        // Enables the plugin for all stores. Defaults to true.
        enable: true,
        // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
        initialize: true,
        // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
        type: "localstorage",
    })
);

const head = createHead();

//configure the application
const app = createApp(App as any)
    .use(router)
    .use(pinia)
    .use(vuetify)
    // @ts-ignore
    .use(VueAxios, globalAxios)
    .use(VueAwesomePaginate)
    .use(FloatingVue)
    .use(head)
    .use(LDPlugin, { clientSideID: process.env.VUE_APP_LAUNCHDARKLY_CLIENT ?? "", deferInitialization: true });

app.provide('axios', globalAxios);
app.provide('publicApiService', new publicApiService(process.env.VUE_APP_PUBLIC_API_URL ?? ""));
app.provide('trackingPixelService', new trackingPixelService());
app.provide('featureFlagService', new FeatureFlagService(process.env.VUE_APP_LAUNCHDARKLY_CLIENT ?? ""));

app.directive('click-outside', {
    beforeMount: function (element, binding) {
        //  check that click was outside the el and his children
        element.clickOutsideEvent = function (event) {
            // and if it did, call method provided in attribute value
            if (!(element === event.target || element.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', element.clickOutsideEvent)
    },
    unmounted: function (element) {
        document.body.removeEventListener('click', element.clickOutsideEvent)
    }
});

app.mount("#app");